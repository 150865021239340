<script setup>
import ThemeButton from "@/components/ThemeButton.vue";
</script>
<template>
  <b-navbar toggleable="lg" type="dark">
    <b-navbar-brand class="brand-text" to="/"
      >Mile<span style="color: red">O</span>graph</b-navbar-brand
    >

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item to="/">Home</b-nav-item>
        <b-nav-item to="/legs">Legs</b-nav-item>
        <b-nav-item to="/traction">Traction</b-nav-item>
        <b-nav-item to="/calc">Calculator</b-nav-item>
        <b-nav-item to="/input">Add</b-nav-item>
        <b-nav-item to="/about">About</b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown class="dropdown-nav" right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em>{{ username }}</em>
          </template>
          <ThemeButton></ThemeButton>
          <b-dropdown-item href="admin">Admin</b-dropdown-item>
          <b-dropdown-item href="logout">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  data() {
    return {
      username: "User",
    };
  },
};
</script>

<style scoped>
.brand-text {
  font-weight: bolder;
  font-family: Tomatoes;
}

.navbar {
  background-color: var(--accent-colour-bg);
}
</style>
