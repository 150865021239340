<template>
  <b-modal
    @ok="submitFunction"
    ref="edit-modal"
    content-class="modal-background"
  >
    <h1 v-if="this.$props.addMode">Add New Traction</h1>
    <h1 v-else>Edit Traction</h1>
    <div class="update-form-container">
      <b-input-group prepend="Type"
        ><b-form-select
          v-model="updateForm.loco_type"
          :options="typeSelectionList"
          class="loco-input-box"
          >Select a type</b-form-select
        >
      </b-input-group>
      <b-input-group
        id="input-group-traction-number"
        prepend="Number"
        label-for="input-traction-number"
      >
        <b-form-input
          id="input-traction-number"
          v-model="updateForm.loco_number"
          class="centered input-form-text"
          required
        >
        </b-form-input>
      </b-input-group>
      <b-input-group
        id="input-group-traction-evn"
        prepend="EVN"
        label-for="input-traction-evn"
      >
        <b-form-input
          id="input-traction-evn"
          v-model="updateForm.loco_evn"
          class="centered input-form-text"
          required
        >
        </b-form-input>
      </b-input-group>
      <b-input-group
        id="input-group-traction-name"
        prepend="Name"
        label-for="input-traction-name"
      >
        <b-form-input
          id="input-traction-name"
          v-model="updateForm.loco_name"
          class="centered input-form-text"
        >
        </b-form-input>
      </b-input-group>
      <b-input-group
        id="input-group-traction-class"
        prepend="Class"
        label-for="input-traction-class"
      >
        <b-form-input
          id="input-traction-class"
          v-model="updateForm.loco_class"
          class="centered input-form-text"
        >
        </b-form-input>
      </b-input-group>
      <b-input-group
        id="input-group-traction-operator"
        prepend="Operator"
        label-for="input-traction-operator"
      >
        <b-form-input
          id="input-traction-operator"
          v-model="updateForm.loco_operator"
          class="centered input-form-text"
        >
        </b-form-input>
      </b-input-group>
      <b-input-group
        id="input-group-traction-notes"
        prepend="Notes"
        label-for="input-traction-notes"
      >
        <b-form-input
          id="input-traction-notes"
          v-model="updateForm.loco_notes"
          class="centered input-form-text"
        >
        </b-form-input>
      </b-input-group>
    </div>

    <b-alert v-model="alertShow" :variant="alertVariant"
      >{{ this.alertText }}
    </b-alert>
  </b-modal>
</template>

<script>
import { fetchWrapper } from "@/helpers/fetchWrapper";

export default {
  name: "EditLocoPanel",
  props: {
    addMode: Boolean,
  },
  data() {
    return {
      typeSelectionList: [
        { value: "S", text: "Steam" },
        { value: "D", text: "Diesel/Electric" },
        { value: "U", text: "Unit" },
      ],
      updateForm: {
        loco_id: null,
        loco_type: null,
        loco_number: null,
        loco_name: null,
        loco_class: null,
        loco_operator: null,
        loco_notes: null,
        loco_evn: null,
      },
      alertShow: false,
      alertVariant: "danger",
      alertText:
        "There is no alert, you shouldn't be able to see this at the moment.",
    };
  },
  computed: {
    submitFunction: function () {
      if (this.$props.addMode) {
        return this.addTraction;
      } else {
        return this.submitTraction;
      }
    },
  },
  methods: {
    showModal(traction) {
      if (this.$props.addMode && this.$props.tractionClass) {
        this.updateForm.loco_class = this.$props.tractionClass;
      }
      console.log(traction);
      this.$refs["edit-modal"].show();
      if (traction) {
        this.updateForm.loco_id = traction.loco_id;
        this.updateForm.loco_type = traction.loco_type;
        this.updateForm.loco_number = traction.loco_number;
        this.updateForm.loco_name = traction.loco_name;
        this.updateForm.loco_class = traction.loco_class;
        this.updateForm.loco_operator = traction.loco_operator;
        this.updateForm.loco_notes = traction.loco_notes;
        this.updateForm.loco_evn = traction.loco_evn;
      }
    },
    async submitTraction(e) {
      e.preventDefault();
      this.alertText = "Updating Loco";
      this.alertVariant = "info";
      this.alertShow = true;
      fetchWrapper
        .post(`${process.env.VUE_APP_API_URL}/loco/update`, this.updateForm)
        .then(() => {
          this.alertText = "Loco Updated";
          this.alertVariant = "success";
          this.alertShow = true;
          this.$refs["edit-modal"].hide();
          this.$emit("on-complete");
          this.alertShow = false;
        })
        .catch((rej) => {
          this.alertText = rej;
          this.alertVariant = "danger";
          this.alertShow = true;
        });
    },
    async addTraction(e) {
      e.preventDefault();
      this.alertText = "Adding Loco";
      this.alertVariant = "info";
      this.alertShow = true;
      fetchWrapper
        .put(`${process.env.VUE_APP_API_URL}/loco/new`, this.updateForm)
        .then(() => {
          this.alertText = "Loco Added";
          this.alertVariant = "success";
          this.alertShow = true;
          this.$refs["edit-modal"].hide();
          this.$emit("on-complete");
          this.alertShow = false;
        })
        .catch((rej) => {
          this.alertText = rej;
          this.alertVariant = "danger";
          this.alertShow = true;
        });
    },
  },
  mounted() {
    //if (!this.$props.addMode) {
    //  this.getLocoInformation();
    //}
  },
};
</script>

<style lang="css" scoped>
select,
input {
  background-color: var(--background-color-secondary);
  color: var(--text-primary-color);
  border: none;
}
input:focus {
  background-color: var(--background-color-tirtiary);
  color: var(--text-primary-color);
}

.input-group {
  margin-bottom: 10px;
}
</style>
