<template>
  <div class="admin" v-if="show">
    <h1>{{ titleText }}</h1>
    <div class="admin-content" v-if="elevated">
      <h4>Update Routing Data</h4>
      <input type="file" @change="filesPicked" multiple accept=".xlsx" />
      <b-button variant="primary" @click="uploadFiles">Upload</b-button>
      <b-alert v-model="alertShow" :variant="alertVariant"
        >{{ this.alertText }}
      </b-alert>
    </div>
  </div>
</template>
<script>
import { fetchWrapper } from "@/helpers/fetchWrapper";
export default {
  name: "AdminView",
  data() {
    return {
      alertShow: false,
      alertVariant: "success",
      alertText: "Data uploaded",
      titleText: "Admin",
      elevated: false,
      show: false,
      files: [],
    };
  },
  methods: {
    filesPicked(event) {
      const files = event.target.files;
      console.log(files);
      this.files = files;
    },
    async getElevation() {
      fetchWrapper
        .get(`${process.env.VUE_APP_API_URL}/elevated`)
        .then((data) => {
          if (data.detail === "User has elevated privileges") {
            this.elevated = true;
          } else {
            this.titleText = "You do not have permission to access this page.";
          }
          this.show = true;
        });
    },
    async uploadFiles() {
      if (this.files.length == 0) {
        console.log("No files!");
      } else {
        fetchWrapper
          .files(`${process.env.VUE_APP_API_URL}/route/update`, this.files)
          .then((data) => {
            this.alertShow = true;
            console.log(data);
          })
          .catch((rej) => {
            this.alertShow = true;
            this.alertVariants = "danger";
            this.alertText = rej;
          });
      }
    },
  },
  beforeMount() {
    this.getElevation();
  },
};
</script>
