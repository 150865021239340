import router from "@/router";
import { useAuthStore } from "../store/auth.store";

export const fetchWrapper = {
  get: request("GET"),
  post: request("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
  login: loginRequest(),
  register: registerRequest(),
  files: fileUploadRequest(),
};

function request(method) {
  //console.log("sending request");
  return async (url, body) => {
    const requestOptions = {
      method,
      headers: authHeader(url),
    };
    if (body) {
      requestOptions.headers["Content-Type"] = "application/json";
      requestOptions.body = JSON.stringify(body);
    }
    //console.log(body, url, method, requestOptions);
    const response = await fetch(url, requestOptions);
    return handleResponse(response);
  };
}

function fileUploadRequest() {
  //console.log("sending request");
  return async (url, files) => {
    const requestOptions = {
      method: "POST",
      headers: authHeader(url),
    };

    let fd = new FormData();
    if (files) {
      Array.from(files).forEach((file) => {
        fd.append("files", file);
      });
      requestOptions.body = fd;
    }
    //console.log(body, url, method, requestOptions);
    console.log(fd);
    for (let pair of fd.entries()) {
      console.log(pair[0], pair[1].name); // Should log multiple "files" entries
    }
    const response = await fetch(url, requestOptions);
    return handleResponse(response);
  };
}

function loginRequest() {
  return async (url, body) => {
    const requestOptions = {
      method: "POST",
      headers: authHeader(url),
    };
    if (body) {
      requestOptions.headers["Content-Type"] =
        "application/x-www-form-urlencoded";
      requestOptions.body = JSON.stringify(body);
    }
    return fetch(url, requestOptions).then(handleResponse);
  };
}

function registerRequest() {
  return async (url, body) => {
    const requestOptions = {
      method: "POST",
      headers: {},
    };
    if (body) {
      requestOptions.headers["Content-Type"] =
        "application/x-www-form-urlencoded";
      requestOptions.body = JSON.stringify(body);
    }
    return fetch(url, requestOptions).then(handleResponse);
  };
}

// helper functions

function authHeader(url) {
  // return auth header with jwt if user is logged in and request is to the api url
  const { user } = useAuthStore();
  const isLoggedIn = !!user?.access_token;
  const isApiUrl = url.startsWith(`${process.env.VUE_APP_API_URL}`);
  if (isLoggedIn && isApiUrl) {
    return { Authorization: `Bearer ${user.access_token}` };
  } else {
    //console.log("Login/apiURL check failed");
    return {};
  }
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      const { user, logout } = useAuthStore();
      if ([401, 403].includes(response.status) && user) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        useAuthStore().returnUrl = router.currentRoute.fullPath;
        logout();
      }
      console.log(data);
      console.log(response);
      //const error = (data && data.message) || response.statusText;
      return Promise.reject(data.detail);
    }

    return data;
  });
}
